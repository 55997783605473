import { template as template_82d43240b2794619ac14291e4160b907 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_82d43240b2794619ac14291e4160b907(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
