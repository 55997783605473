import { template as template_dd56271092304691a2d3d7c800fff029 } from "@ember/template-compiler";
const FKControlMenuContainer = template_dd56271092304691a2d3d7c800fff029(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
