import { template as template_b7cf6ce89f654dd9ac1a01c3a97f76b3 } from "@ember/template-compiler";
import { or } from "truth-helpers";
import replaceEmoji from "discourse/helpers/replace-emoji";
import SectionLink from "./section-link";
const SidebarMoreSectionLink = template_b7cf6ce89f654dd9ac1a01c3a97f76b3(`
  <SectionLink
    @badgeText={{@sectionLink.badgeText}}
    @content={{replaceEmoji @sectionLink.text}}
    @currentWhen={{@sectionLink.currentWhen}}
    @href={{or @sectionLink.href @sectionLink.value}}
    @linkName={{@sectionLink.name}}
    @model={{@sectionLink.model}}
    @models={{@sectionLink.models}}
    @prefixType="icon"
    @prefixValue={{@sectionLink.prefixValue}}
    @query={{@sectionLink.query}}
    @route={{@sectionLink.route}}
    @shouldDisplay={{@sectionLink.shouldDisplay}}
    @suffixCSSClass={{@sectionLink.suffixCSSClass}}
    @suffixType={{@sectionLink.suffixType}}
    @suffixValue={{@sectionLink.suffixValue}}
    @title={{@sectionLink.title}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarMoreSectionLink;
